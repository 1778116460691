import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import beaver from "beaverboy.png";


import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";
import { url } from "inspector";
import { randomInt } from "crypto";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main style={ {
        //backgroundColor: "pink",
        display: "flex",
        height: "100vh", 
      }}>

      <div style = {{padding: 30,  display: "flex", flexDirection: "column", flex: 1  }}> 
          
          <div style={ {
        //  backgroundColor: "green",
          display: "flex",
          justifyContent: "space-between"
          }}> 
                  {wallet && (
                    <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
                  )}
                  <ConnectButton>{wallet ? "Connected" : "Connect Wallet"}</ConnectButton>
            
            </div> 

            <div style={ {
           //   backgroundColor: "blue", 
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems:"center"
            }}>
 
            <div>  
            <b><p>Beavercrush Solana NFT minting page</p></b>

            <div 
            style={{ backgroundImage: "url(/HeaderImage.png)", width: "800px", height: "400px",
            //flex: 1,
            //display: "flex", 
            justifyContent: "center",
            alignContent: "center",
            alignItems:"center",
            }}
            > </div>

  
     <div id="mintcontainer" style={{  width: "100%", height: "100%", display: "flex", padding: 30}}>
     
              <MintContainer>
              <b><p>0.3 Sol</p></b>
              <MintButton
                disabled={isSoldOut || isMinting || !isActive}
                onClick={onMint}
                variant="contained"
              >
                {isSoldOut ? (
                  <p >"SOLD OUT"</p>
                ) : isActive ? (
                  isMinting ? (
                    <CircularProgress />
                  ) : (
                    "MINT"
                  )
                ) : (
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => setIsActive(true)}
                    renderer={renderCounter}
                  />
                )}
              </MintButton>
              <p style={{color: "green"}}>People currently shopping for beavers: {157 + Math.floor(Math.random() * 3)} </p>
              <b>    {wallet && <p >Supply: {itemsAvailable-itemsRedeemed} / {itemsAvailable}</p>}</b>
  
              </MintContainer> 
          
      
    </div>

              <p>This is a pre listing mint! Fair launch! No white list or anything!</p>
              <p>Price 0.3 Sol - Planed floor price at least 3 Sol</p>


              <p> - - - - - - - - - - - - - - - - - - - - -  </p>

              <p>Join our <a style={{ color: "hotpink"}} href=" https://discord.gg/F8pWfw9JZX">Discord</a>.</p>


              <p>Features </p>
              <p>- There will be 5 evil otters hidden in all of the beavers. They will be incredibly rare!</p>
              <p>- Look out for the very rare Sharigan eye beavers</p>
              <p>- All beavers have a special name and rarity features</p>
              <p>- All avatars will be usable in the game <a style={{ color: "hotpink"}} href="https://beavercrush.com/game.html">Beavercrush</a> later. It's already in the google play store.</p>
              <p>- Listed on SolSeas <a style={{ color: "hotpink"}} href="https://solsea.io/collection/619b577c5687df75fd50020e">SolSea Collection</a> later. It's already in the google play store.</p>


              <div id="container" style={{  width: "100%", height: "100%", display: "flex"}}>
                  <div id="left" style={{ backgroundImage: "url(/BeaverPreview.png)", width: "160px", height: "150px"}}>994 Beavers</div>
                  <div id="middle" style={{ backgroundImage: "url(/EvilOtter.png)", width: "160px", height: "150px"}}>5 Evil Otters</div>
                  <div id="right" style={{ backgroundImage: "url(/RabbitKing.png)", width: "160px", height: "150px"}}>1 Unique Rabbit King</div>
              </div>

              <p> - - - - - - - - - - - - - - - - - - - - -  </p>


              <p>Road map</p>
              <p>Friday 26.11.2021 Miniting starts with a very small floorprice in a fair launch!</p>
              <p>Saturday 27.11.2021 Miniting will be stopped!</p>
              <p>Sunday 28.11.2021 listing on <a style={{ color: "hotpink"}} href="https://solanart.io/collections/">Solanart</a> where you will be able to sell the beavers for a profit</p>
              <p>Sunday 28.12.2021 Christmas special in the game Beavercrush will start</p>
              <p>Q1 2022 Stake your beavers to get log</p>
              <p>Q2 2022 Stake your log to build dams together</p>
              <p>Q4 2022 Break the dams to wash beaver babies down the river</p>
                            
              </div>

            </div>


       </div>




      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
